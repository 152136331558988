<template>
  <div class="container">
    <el-skeleton
      v-if="articles.length === 0"
      class="article"
      :rows="3"
      animated
    />

    <div
      class="article"
      v-for="article in articles"
      :key="article.id"
    >
      <h2
        class="article-title"
        @click="handleViewArticle(article)"
      >{{ article.title }}</h2>
      <span class="article-attr"><i class="el-icon-alarm-clock"></i> {{ article.createtimeString }}</span>
      <span class="article-attr"><i class="el-icon-collection-tag"></i> {{ article.tag }}</span>
      <p class="article-desc">{{ article.desc }}</p>
      <span
        class="article-view"
        @click="handleViewArticle(article)"
      >阅读全文&gt;&gt;</span>
      <i
        v-if="username"
        class="el-icon-edit"
        @click="handleEditArtic(article)"
      ></i>
      <i
        v-if="username"
        class="el-icon-delete"
        @click="handleDeleteArtic(article)"
      ></i>
    </div>

    <el-button
      v-if="username"
      class="new-article-btn"
      type="primary"
      icon="el-icon-edit"
      circle
      @click="handleNewArticle(article)"
    ></el-button>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
import { useRouter } from 'vue-router'
import { timestamp2datestring } from '../../util/utils'
import { reqGetBlogList, reqDeleteBlog } from '../../http/blogApi'
import { ElMessageBox, ElMessage } from 'element-plus'
import { reqCheckUser } from '../../http/userApi'

export default {
  setup () {
    const username = ref('')
    // 定义和获取文章列表
    const articles = ref([])
    onMounted(() => {
      _checkUser()
      _getBlogList()
    })

    const _checkUser = async () => {
      const data = await reqCheckUser()
      username.value = data.username || ''
    }

    const _getBlogList = () => {
      reqGetBlogList().then(data => {
        data.map(item => {
          item.createtimeString = timestamp2datestring(item.createtime)
          const array = item.content.split('\n')
          for (let i = 0; i < array.length; i++) {
            if (array[i] &&
              !array[i].startsWith('#') &&
              !array[i].startsWith('!')) {
              item.desc = array[i]
              break
            }
          }
          return item
        })
        articles.value = data
      }).catch(err => {
        console.log(err)
      })
    }

    // 点击跳转文章
    const router = useRouter()
    const handleViewArticle = (article) => {
      console.log('id', article.id)
      router.push({ name: 'Article', query: { id: article.id } })
    }

    // 点击跳转新建文章
    const handleNewArticle = () => {
      router.push({ name: 'NewArticle' })
    }

    // 编辑文章
    const handleEditArtic = (article) => {
      router.push({ name: 'NewArticle', query: { id: article.id } })
    }

    // 删除文章
    const handleDeleteArtic = (article) => {
      ElMessageBox.confirm('删除该文章?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          _deleteArticle(article.id)
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除'
          })
        })
    }

    const _deleteArticle = (id) => {
      reqDeleteBlog(id).then(() => {
        _getBlogList()
      }).catch(err => {
        console.log(err)
      })
    }

    return {
      username,
      articles,
      handleViewArticle,
      handleNewArticle,
      handleEditArtic,
      handleDeleteArtic
    }
  }
}
</script>

<style scoped>
.container {
  margin: 0 auto;
}

.article {
  max-width: 880px;
  margin: 80px auto;
  padding: 0 12px;
  text-align: start;
}

.article > i {
  margin-left: 16px;
  cursor: pointer;
}

.article-title {
  cursor: pointer;
}

.article-attr {
  margin-right: 40px;
  font-size: 14px;
}

.article-view {
  text-decoration: underline;
  cursor: pointer;
}
.article-view:hover {
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
}

.article-desc {
  white-space: pre-wrap;
}

.new-article-btn {
  position: absolute;
  top: 100px;
  right: 80px;
}
</style>
