
import { get, post, put, deletee } from './axios'

export function reqGetBlogList () {
  return get('/api/blogs')
}

export function reqGetBlogDetail (id) {
  return get('/api/blog', { id })
}

export function reqPostNewBlog (title, content) {
  const blog = { title, content }
  return put('/api/blog', blog, '', '发布失败')
}

export function reqPostImageForBlog (file) {
  const formData = new FormData()
  formData.append('file', file)
  return post('/api/blog/image', formData, '', '图片上传失败')
}

export function reqDeleteBlog (id) {
  return deletee('/api/blog', { id })
}

export function reqUpdateBlog (blog) {
  return post('/api/blog', blog, '', '修改博客失败')
}
