<template>
  <div class="container">
    <div class="article">
      <div class="article-view">
        <span @click="back"> &lt;&lt;返回</span>

        <i
          v-if="username"
          class="el-icon-edit"
          @click="handleEditArtic(article)"
        ></i>
      </div>

      <h2 class="article-title">{{ article.title }}</h2>
      <span class="article-attr">
        <i class="el-icon-alarm-clock"></i>
        {{ article.createtimeString }}
      </span>
      <span class="article-attr">
        <i class="el-icon-collection-tag"></i>
        {{ article.tag }}
      </span>

      <v-md-preview
        class="markdown-preview"
        :text="article.content"
      ></v-md-preview>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
import { useRoute, useRouter } from 'vue-router'
import { reqGetBlogDetail } from '../../http/blogApi'
import { timestamp2datestring } from '../../util/utils'
import { reqCheckUser } from '../../http/userApi'

export default {
  setup () {
    const route = useRoute()

    onMounted(() => {
      _checkUser()
      _getArticle()
    })

    const username = ref('')
    const _checkUser = async () => {
      const data = await reqCheckUser()
      username.value = data.username || ''
    }

    // 定义和获取文章
    const article = ref({})
    const _getArticle = () => {
      const articleId = route.query.id
      reqGetBlogDetail(articleId).then(data => {
        data.createtimeString = timestamp2datestring(data.createtime)
        article.value = data
      }).catch(err => {
        console.log(err)
      })
    }

    // 返回
    const router = useRouter()
    const back = () => {
      router.go(-1)
    }

    // 编辑文章
    const handleEditArtic = (article) => {
      router.push({ name: 'NewArticle', query: { id: article.id } })
    }

    return {
      article,
      username,
      back,
      handleEditArtic
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 880px;
  margin: 0 auto;
  padding: 0 12px;
}

.article {
  margin: 40px 0;
  text-align: center;
}

.article-title {
  cursor: pointer;
}

.article-attr {
  display: inline-block;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 20px;
  font-size: 14px;
}

.markdown-preview {
  text-align: start;
}

.article-view {
  width: 100%;
  display: inline-block;
  text-align: start;
  text-decoration: underline;
  cursor: pointer;
}

.article-view span {
  margin-right: 20px;
}

.article-desc {
  white-space: pre-wrap;
}
</style>
<style>
.markdown-preview img {
  max-width: 100%;
  margin: 12px 0;
}
</style>
