
import { get } from './axios'

/**
 * 检查用户是否登陆
 * @returns {
              "data": {
                "username": "charles"
              },
              "errorno": 0
            }
 */
export function reqCheckUser () {
  return get('/api/user/checkUser')
}
