<template>
  <div class="markdown-wrapper">
    <v-md-editor
      v-model="input"
      @save="handleSaveBlog"
    ></v-md-editor>

    <el-upload
      class="image-upload"
      action=""
      :http-request="handleUploadImage"
    >
      <i class="el-icon-upload"></i>
    </el-upload>

    <el-button
      class="btn-upload"
      type="primary"
      size="mini"
      @click="handlePostBlog"
    >发布</el-button>
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/runtime-core'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import {
  reqGetBlogDetail,
  reqPostNewBlog,
  reqPostImageForBlog,
  reqUpdateBlog
} from '../../http/blogApi'
import { reqCheckUser } from '../../http/userApi'

export default {
  setup () {
    const route = useRoute()
    const router = useRouter()

    // 返回
    const toArticles = () => {
      router.go(-1)
    }

    // 文章内容
    const input = ref('# hello')
    let articleForEdit = {}

    onMounted(async () => {
      // 如果此时用户没有登陆，则返回
      const result = await reqCheckUser()
      if (!result.username) {
        router.go(-1)
      }

      _getCurDoc()
    })

    const _getCurDoc = (id) => {
      const articleId = route.query.id
      if (!articleId) {
        return
      }

      reqGetBlogDetail(articleId).then(data => {
        articleForEdit = data
        input.value = data.content
      }).catch(err => {
        console.log(err)
      })
    }

    /**
     * 上传图片
     */
    const handleUploadImage = (param) => {
      const imageName = param.file.name
      reqPostImageForBlog(param.file).then(data => {
        input.value += `\n![${imageName}](${data.path})`
      }).catch(err => {
        console.log(err)
      })
    }

    // 发布
    const handlePostBlog = () => {
      const content = input.value
      const title = content.match(/(#+)(.*)/)[2].trim()
      if (!title) {
        ElMessage.warning('请输入文章标题')
        return
      }

      if (articleForEdit.id) {
        articleForEdit.content = content
        reqUpdateBlog(articleForEdit).then(data => {
          router.replace({ name: 'Article', query: { id: articleForEdit.id } })
        })
      } else {
        reqPostNewBlog(title, content).then(data => {
          router.replace({ name: 'Article', query: { id: data.id } })
        })
      }
    }

    const handleSaveBlog = () => {
      const content = input.value
      const title = content.match(/(#+)(.*)/)[2].trim()
      if (!title) {
        ElMessage.warning('请输入文章标题')
        return
      }

      if (articleForEdit.id) {
        articleForEdit.content = content
        reqUpdateBlog(articleForEdit).then(data => {
          ElMessage.success('文章已保存')
        })
      } else {
        reqPostNewBlog(title, content).then(data => {
          ElMessage.success('文章已保存')
        })
      }
    }

    return {
      input,
      handlePostBlog,
      toArticles,
      handleUploadImage,
      handleSaveBlog
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  margin: 0 auto;
}

.markdown-wrapper {
  position: relative;
  height: calc(100% - 150px);

  .v-md-editor {
    height: 100%;
  }

  .image-upload {
    position: absolute;
    top: 10px;
    left: 635px;
    cursor: pointer;
  }

  .btn-upload {
    position: absolute;
    top: 6px;
    left: 670px;
    cursor: pointer;
  }
}

.buttons {
  padding: 20px;
}
</style>
